<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 40 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'decadas'" :produtos="tipos_funerarios"
      @filtrar="filterData"  :title_opcao="'Campanha'" 
        ></filtro-component>
    </v-col>
  </v-container>

 



  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
    
   


  <v-row>

    
    <v-col
    cols="12"
    sm="4"
    lg="3">
    
     <base-material-stats-card v-if="!card1_loading" 
       color="blue"
       icon="mdi-file-document-check-outline"
       title="Contratos Ativo"
       sub-icon-color="blue"
       :valuex='false'
        :value='this.cards.contratos_ativos.toString()'
        :sub-text="'Total Cancelado : ' + this.cards.contratos_cancelados.toString()"
        />

        <CardLoadComponent  v-if="card1_loading" />
    

   </v-col>


   <v-col
   cols="12"
   sm="4"
   lg="3">
 
  <base-material-stats-card v-if="!card2_loading"
    color="orange"
    icon="mdi-account-star"
    title="Total de Vidas"
    sub-icon-color="red"
    :value='this.cards.vidas'
    :valuex='false'
    :sub-text="'Total de Titulares : ' + this.cards.total_titulares"
  />
 
  <CardLoadComponent  v-if="card2_loading" />

</v-col>


  <v-col
   cols="12"
   sm="4"
   lg="3">
   
    <base-material-stats-card v-if="!card3_loading" 
      color="purple"
      icon="mdi-account-group"
      title="Dependente"
      sub-icon-color="red"
      :valuex='false'
      :value='this.cards.dependentes.toString()'
      :sub-text="'Total Falecido : ' + this.cards.dependentes_falecidos.toString()"
    />

    <CardLoadComponent  v-if="card3_loading" />

  </v-col>

  <v-col
  cols="12"
  sm="4"
  lg="3">
  
   <base-material-stats-card  v-if="!card4_loading" 
     color="green"
     icon="mdi-account-group"
     title="Agregado"
     sub-icon-color="red"
     :value='this.cards.agregados'
     :valuex='false'
     :sub-text="'Total Falecido : ' + this.cards.agregados_falecidos.toString()"
     />

     <CardLoadComponent  v-if="card4_loading" />

  
 </v-col>



  </v-row>

  <v-row>
    
    <v-col
    cols="12"
    sm="6"
    lg="3">
    <CircleWithText :borderColor="'#FF0000	'"    :complemento="'ANOS'" :innerValue="this.media_uso" title="Tempo Médio para Uso do 1º Serviço " />
  </v-col>

    <v-col
    cols="12"
    sm="6"
    lg="3">
<CircleWithText :borderColor="'#00FA9A	'" :innerValue="this.media_titular"  :complemento="'ANOS'" title="Média Geral de Idade dos Titulares Ativos " />

    </v-col>

    <v-col
    cols="12"
    sm="6"
    lg="3">
    <CircleWithText :borderColor="'#0000CD	'"  :complemento="'ANOS'" :innerValue="this.media_dependente" title="Média Geral de Idade dos Dependentes Ativos" />

    </v-col>

    <v-col
    cols="12"
    sm="6"
    lg="3">
    <CircleWithText :borderColor="'orange	'"  :complemento="'ANOS'" :innerValue="this.media_agregado" title="Média Geral de Idade dos Agregados Ativos" />

    </v-col>

  </v-row>


  <v-row>
    
    <v-col
    cols="12"
    sm="6"
    lg="6">



    <v-card v-if="!faixa_ativo_contrato_loading" color="white" dark  style="height: 400px !important;" elevation="1">
      <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Totais de Contrato Por Tempo - Ativos</v-card-title>
      <apexcharts type="bar" height="350" :options="chartOptionsContratosPorTempo" :series="gf_6_series" :colors="['red', 'blue', 'orange', '#0334400', '#FFF', '#838383']"></apexcharts>
    </v-card>
    <v-card v-if="faixa_ativo_contrato_loading" color="white" dark style="height: 400px !important" elevation="1">
      <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Totais de Contrato Por Tempo - Ativos</v-card-title>
        <content-loader primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed=1 height=350></content-loader> 
    </v-card>

    </v-col>


    <v-col
    cols="12"
    sm="6"
    lg="6">



    <v-card v-if="!hxm" color="white" dark  style="height: 400px !important;" elevation="1">
      <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Média de Idade - Falecidos</v-card-title>
      <apexcharts type="bar" height="350" :options="chartOptionsContratosHxM" :series="gf_7_series" ></apexcharts>
    </v-card>
    <v-card v-if="hxm" color="white" dark style="height: 400px !important" elevation="1">
      <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Média de Idade - Falecidos</v-card-title>
        <content-loader primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed=1 height=350></content-loader> 
    </v-card>

    </v-col>


   




    </v-row>

  <v-row>
    
    <v-col
    cols="12"
    sm="6"
    lg="4">
     
      <v-card v-if="!tipo_contrato_loading" color="white" dark  style="height: 320px !important;" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Percentual de Plano com Sinistro %</v-card-title>
        <apexcharts type="pie"    :options="generatePieChartOptions(this.gf_5_label, this.gf_5_color)" :series="gf_5_series"></apexcharts>
      </v-card>
      <v-card v-if="tipo_contrato_loading" color="white" dark style="height: 320px !important" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Percentual de Plano com Sinistro %</v-card-title>
          <content-loader primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed=1 height="350"></content-loader> 
      </v-card>

    </v-col>

    <v-col
    cols="12"
    sm="6"
    lg="4">
      
      <v-card v-if="!contrato_sinistro_loading" color="white" dark  style="height: 320px !important;" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipo de Contrato de Plano %</v-card-title>
        <apexcharts    type="pie"  :options="generatePieChartOptions(this.gf_3_label, this.gf_3_color)" :series="gf_3_series"></apexcharts>
      </v-card>
      <v-card v-if="contrato_sinistro_loading" color="white" dark style="height: 320px !important" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipo de Contrato de Plano %</v-card-title>
          <content-loader primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed="1" height="350"></content-loader> 
      </v-card>

    </v-col>

    <v-col
    cols="12"
    sm="6"
    lg="4">

      <v-card v-if="!motivo_cancelamento_loading" color="white" dark style="height: 320px !important;" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipos de Cancelamento de Plano %</v-card-title>
        <apexcharts type="pie"   :options="generatePieChartOptions(this.gf_4_label, this.gf_4_color)" :series="gf_4_series"></apexcharts>
      </v-card>
      <v-card v-if="motivo_cancelamento_loading" color="white" dark style="height: 320px !important" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipos de Cancelamento de Plano %</v-card-title>
          <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed=1 height=350></content-loader> 
      </v-card>


    </v-col>


   

  
    </v-row>

  <v-row>
    
    <v-col
    cols="12"
    sm="6"
    lg="6">
      

      <v-card v-if="!titular_agrupado_loading" color="white" dark style="height: 420px !important;" elevation="1" >
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Grupo de Idade - Titulares</v-card-title>
        <v-card-text>
          <apexcharts width="500" height="380" type="bar" :options="generateBarChartOptions(this.gf_1_label, this.gf_1_color, this.gf_1_series)" :series="gf_1_series"></apexcharts>
        </v-card-text>
      </v-card>
      <v-card v-if="titular_agrupado_loading" color="white" dark style="height: 300px !important" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Grupo de Idade - Titulares</v-card-title>
          <content-loader primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed=1 height="300"></content-loader> 
      </v-card>

    </v-col>

    <v-col
    cols="12"
    sm="6"
    lg="6">

      <v-card v-if="!dependente_agrupado_loading" color="white" dark style="height: 420px !important;" elevation="1" >
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Grupo de Idade - Dependente, Agregados e Outros Resp.</v-card-title>
        <v-card-text>
          <apexcharts width="500" height="350" type="bar" :options="generateBarChartOptions(this.gf_2_label, this.gf_2_color, this.gf_2_series)" :series="gf_2_series"></apexcharts>
        </v-card-text>
      </v-card>
      <v-card v-if="dependente_agrupado_loading" color="white" dark style="height: 300px !important" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Grupo de Idade - Dependente e Agregados</v-card-title>
          <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  speed=1
          height="300"></content-loader> 
      </v-card>

  </v-col>

  </v-row>



  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Contrato from '../../services/contrato'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto
import CardLoadComponent from "./components/CardLoad.vue"; // Certifique-se de que o caminho esteja correto
import { ContentLoader } from 'vue-content-loader'
import CircleWithText from "./components/CircleCard.vue";


  export default {
  components: {
    apexcharts: VueApexCharts,
    FiltroComponent,
    ContentLoader,
    CardLoadComponent,
    CircleWithText

  },
  
  created() {
      this.selectedStartDate = this.getFirstDayOf60YearsAgo(),
      this.selectedEndDate =  this.formatDateBR(new Date())
    },
    
  mounted() {
    this.selectedCompany = "Todas",
    this.dashboard();
    this.medias();
    this.gf01();
    this.gf02();
    this.gf03();
    this.gf04();
    this.gf05();
    this.gf06();
    this.gf07();
  },

    name: 'Contrato',
 
    data () {
      return {
       
        fill : { gradient: ["red", "green", "blue"] },

        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          produto: 'Todos',
          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectMode: "",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todas","Campanha 50 Anos"],
          media_uso: 0,
          media_titular: 0,
          media_dependente: 0,
          media_agregado: 0,
          modos: ["Cadastro"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos e Cemiteriais"],

          cards: {
              contratos_ativos: 0,
              contratos_ativos_json: '',
              contratos_cancelados: 0,
              contratos_cancelados_json: '',
              titulares: 0,
              titulares_json: 0,
              vidas: 0,
              vidas_json: 0,
              dependentes:0,
              dependentes_json:0,
              agregados: 0,
              agregados_json: 0,
              dependentes_falecidos: 0,
              dependentes_falecidos_json: 0,
              agregados_falecidos: 0,
              agregados_falecidos_json: 0,
              total_titulares: 0,
              total_titulares_json: 0,
          },

         

          titular_agrupado_json:0,
          gf_1_label: ['0 a 17', '18 a 30', '31 a 40', '41 a 50', '51 a 60', '61 a 70', '71 a 80', '81 a 90', '+ 90'],
          gf_1_color: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4' , '#f9a3a4', '#f9a3a4'],
          gf_1_series: [{data: []}],

         

          dependente_agrupado_json:0,
          gf_2_label: ['0 a 17', '18 a 30', '31 a 40', '41 a 50', '51 a 60', '61 a 70', '71 a 80', '81 a 90', '+ 90'],
          gf_2_color: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4' , '#f9a3a4', '#f9a3a4'],
          gf_2_series: [{ data: []}],

          tipo_contrato_json:0,
         

          gf_3_label: [],
          gf_3_color: ['#3498DB', '#F39C12', '#4CAF50'], // Azul e amarelo
          gf_3_series: [{ data: []}],

          motivo_cancelamento_json:0,
        

          gf_4_label: ['', ''],
          gf_4_color: ['#9c27b0', '#4CAF50', '#FF0000','#546E7A', '#d4526e', '#13d8aa'], 
          gf_4_series: [{ data: []}],

          contrato_sinistro_json: 0,
         
          gf_5_label: ['Sim', 'Não'],
          gf_5_color: [  '#4CAF50', '#FF0000'], 
          gf_5_series: [{data: []}],

          gf_6_color: ['#3498DB', '#F39C12', '#4CAF50'], // Azul e amarelo
          gf_6_categoria: ["Até 1 Ano", "Até 3 Ano", "Até 5 Ano", "Até 10 Ano", "Mais de 10 Ano"],
          gf_6_series: [
            {
              name: "Total",
              data: [0, 0, 0, 0, 0]
            }
          ],

          gf_7_series: [
            {
              name: "Total",
              data: [0, 0]
            }
          ],

          chartOptionsContratosHxM: {
        
                  chart: {
                    id: "basic-bar",
                    animations: {
                      speed: 200
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                  fontSize: '12px',
                  colors: ["black"]
                }
                    
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      dataLabels: {
                        position: 'top', // top, center, bottom
                        
                      },
                    }
                  },
                  xaxis: {
                    categories: ["Homens", "Mulheres"],
                  
                  },

                
          },

          chartOptionsContratosPorTempo: {
        
                    chart: {
                      id: "basic-bar",
                      animations: {
                        speed: 200
                      }
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                    fontSize: '12px',
                    colors: ["black"]
                  }
                      
                    },
                    plotOptions: {
                      bar: {
                        distributed: true,
                        dataLabels: {
                          position: 'top', // top, center, bottom
                          
                        },
                      }
                    },
                    //{"ate1":9340,"ate3":8964,"ate5":8591,"ate10":12419,"mais10":26557}
                    xaxis: {
                      categories: ["Até 1 Ano", "Até 3 Ano", "Até 5 Ano", "Até 10 Ano", "Mais de 10 Ano"],
                    
                    },

                  
          },


          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],
          


          titular_agrupado_loading:true,
          dependente_agrupado_loading:true,
          tipo_contrato_loading:true,
          faixa_ativo_contrato_loading:true,
          hxm:true,
          contrato_sinistro_loading:true,
          motivo_cancelamento_loading:true,
          card1_loading:true,
          card2_loading:true,
          card3_loading:true,
          card4_loading:true,
          chartOptions:{},

         
     
          

      }
  },

    methods: {

      progress(event,progress,stepValue){
        console.log(stepValue);
      },
      progress_end(event){
        console.log("Circle progress end");
      },

      getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

        getFirstDayOf60YearsAgo() {
            const dataAtual = new Date();
            const anoAtual = dataAtual.getFullYear();
            const ano60Atras = anoAtual - 60;
            const primeiroDiaDoAno60Atras = new Date(ano60Atras, 0, 1); // Janeiro é representado como 0
            return this.formatDateBR(primeiroDiaDoAno60Atras);
      },

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },

       filterData(valores) {

         this.isLoading = true;
         let nova_consulta = false;
         this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.produto = valores.selectedProduto;

          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate) {
            nova_consulta = true;
          }

          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;


           
          this.titular_agrupado_loading = true,
          this.dependente_agrupado_loading = true,
          this.tipo_contrato_loading = true,
          this.contrato_sinistro_loading = true,
          this.motivo_cancelamento_loading = true,

          this.card1_loading =true,
          this.card2_loading =true,
          this.card3_loading =true,
          this.card4_loading =true,

            this.dashboard();
            this.medias();
            this.gf01();
            this.gf02();
            this.gf03();
            this.gf04();
            this.gf05();
            this.gf06();
            this.gf07();
            this.isLoading = false;
           
        

      },

      recerregarDados(){

          const total_a = this.calcularTotalAcumulado(this.cards.contratos_ativos_json, "Todos", this.selectedType, this.selectedCompany);                      
          this.cards.contratos_ativos = total_a;

          const total_b = this.calcularTotalAcumulado(this.cards.vidas_json, "Todos", this.selectedType, this.selectedCompany);                      
          this.cards.vidas = total_b;

          const total_c = this.calcularTotalAcumulado(this.cards.dependentes_json, "Todos", this.selectedType, this.selectedCompany);                      
          this.cards.dependentes = total_c;

          const total_d = this.calcularTotalAcumulado(this.cards.dependentes_falecidos_json, "Todos",  this.selectedType, this.selectedCompany);                      
          this.cards.dependentes_falecidos = total_d;

          const total_e = this.calcularTotalAcumulado(this.cards.agregados_json, "Todos", this.selectedType, this.selectedCompany);                      
          this.cards.agregados = total_e;
         

          const total_f = this.calcularTotalAcumulado(this.cards.agregados_falecidos_json, "Todos", this.selectedType, this.selectedCompany);                      
          this.cards.agregados_falecidos = total_f;

          const total_g = this.calcularTotalAcumulado(this.cards.contratos_cancelados_json, "Todos", this.selectedType, this.selectedCompany);                      
          this.cards.contratos_cancelados = total_g;

          const total_h = this.calcularTotalAcumulado(this.cards.total_titulares_json, "Todos", this.selectedType, this.selectedCompany );                      
          this.cards.total_titulares = total_h;
          

          this.filtrarTotalGraficos(this.cards.contrato_sinistro_json, "Todos", this.selectedType, this.selectedCompany, "label", "qtd", "5" );                      
          this.filtrarTotalGraficos(this.cards.motivo_cancelamento_json, "Todos", this.selectedType, this.selectedCompany, "label", "qtd", "4");                      
          this.filtrarTotalGraficos(this.cards.tipo_contrato_json, "Todos", this.selectedType, this.selectedCompany, "label", "qtd", "3");                      
          this.filtrarTotalGraficos(this.cards.titular_agrupado_json, "Todos", this.selectedType, this.selectedCompany, "label", "qtd", "1");                      
          this.filtrarTotalGraficos(this.cards.dependente_agrupado_json, "Todos", this.selectedType, this.selectedCompany, "label", "qtd", "2");                      


          this.isLoading = false;

        },



        async gf01() {
          try {
            const response = await Contrato.total_agrupar_idade_titular(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto);
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.cards.titular_agrupado_json = response.data;
              this.filtrarTotalGraficos(this.cards.titular_agrupado_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "1");                      

            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            this.titular_agrupado_loading = false;
            }
        },

        async gf02() {
          try {
            const response = await Contrato.total_agrupar_idade_dep(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto);
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              this.cards.dependente_agrupado_json = response.data;
              this.filtrarTotalGraficos(this.cards.dependente_agrupado_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "2");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            this.dependente_agrupado_loading = false;
            }
        },

        async gf03() {
          try {
            const response = await Contrato.tipo_contrato(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto);
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              this.cards.tipo_contrato_json = response.data;
              this.filtrarTotalGraficos(this.cards.tipo_contrato_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "3");                      
            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            this.tipo_contrato_loading = false;
            }
        },

        async gf04() {
          try {
            const response = await Contrato.motivo_cancelamento(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto);
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              this.cards.motivo_cancelamento_json = response.data;
              this.filtrarTotalGraficos(this.cards.motivo_cancelamento_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "4");                      

            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          } finally {
              this.motivo_cancelamento_loading = false;    
            }
        },

        async gf05() {
          try {
            const response = await Contrato.contrato_sinistro(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto);
            if (response.status === 200) {
              // Extrair os dados da resposta da API

                    this.cards.contrato_sinistro_json = response.data;
                    this.filtrarTotalGraficos(this.cards.contrato_sinistro_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "5");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            this.contrato_sinistro_loading = false; // Os dados foram carregados

            }
        },
        
        async gf06() {
          try {
            const response = await Contrato.contrato_total_faixa_at(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, this.selectedOpcao, this.produto);
              if (response.status === 200) {
                const valorAte1 = response.data.ate1;
                const valorAte3 = response.data.ate3;
                const valorAte5 = response.data.ate5;
                const valorAte10 = response.data.ate10;
                const valormais10 = response.data.mais10;

            
                const newSeries = [];
                newSeries.push(valorAte1);
                newSeries.push(valorAte3);
                newSeries.push(valorAte5);
                newSeries.push(valorAte10);
                newSeries.push(valormais10);

                this.gf_6_series = [{ data: newSeries }];



              }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            this.faixa_ativo_contrato_loading = false; // Os dados foram carregados

            }
        },

        async gf07() {
          try {
            const response = await Contrato.media_h_m(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, this.selectedOpcao, this.produto);
              if (response.status === 200) {
                const valorAte1 = response.data.ate1;
                const valorAte3 = response.data.ate3;
            
                const newSeries = [];
                newSeries.push(valorAte1);
                newSeries.push(valorAte3);

                this.gf_7_series = [{ data: newSeries }];



              }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            this.hxm = false; // Os dados foram carregados

            }
        },

        

       


      calcularTotalAcumulado(lista, adesao, tipo, nome_empresa) {
          let totalAcumulado = 0;

          if (adesao === "Todos") {
            adesao = null;
          }
          if (tipo === "Todos") {
            tipo = null;
          }
         
          for (const item of lista) {

            const empresasSplit = nome_empresa.split(',');
            const empresas = empresasSplit.map(e => e.trim());
            const empresaIgual = empresas.includes(item.empresa) || empresas.includes("TODAS") || empresas.includes("Todas");
            const adesaoIgual = !adesao || item.adesao === adesao
            const tipoIgual = !tipo || item.tipo === tipo;

            if (adesaoIgual && tipoIgual && empresaIgual) {
              totalAcumulado += item.qtd;

            }
          }


          return totalAcumulado;
      },

      filtrarTotalGraficos(lista, adesao, tipo, nome_empresa, campoLabel, campoSerie, gf) {
          let totalAcumulado = 0;

          const newLabels = [];
          const newSeries = [];


          if (adesao === "Todos") {
            adesao = null;
          }
          if (tipo === "Todos") {
            tipo = null;
          }
        

          for (const item of lista) {
           
            const empresasSplit = nome_empresa.split(',');
            const empresas = empresasSplit.map(e => e.trim());
            const empresaIgual = empresas.includes(item.empresa) || empresas.includes("TODAS") || empresas.includes("Todas");
            const adesaoIgual = !adesao || item.adesao === adesao
            const tipoIgual = !tipo || item.tipo === tipo;

              if (adesaoIgual && tipoIgual && empresaIgual) {

                const index = newLabels.indexOf(item[campoLabel]);

                if (index !== -1) {
                  newSeries[index] += item[campoSerie];
                } else {
                  newLabels.push(item[campoLabel]);
                  newSeries.push(item[campoSerie]);
                }
              }
            }
          

            if(gf=== '5'){
              this.gf_5_label = newLabels;
              this.gf_5_series = newSeries;
            }else if(gf=== '4'){
              this.gf_4_label = newLabels;
              this.gf_4_series = newSeries;
            }else if(gf=== '3'){
              this.gf_3_label = newLabels;
              this.gf_3_series = newSeries;
            }else if(gf=== '2'){
              this.gf_2_label = newLabels;
              this.gf_2_series = [{ data: newSeries }];
            }else if(gf=== '1'){
              this.gf_1_label = newLabels;
              this.gf_1_series = [{ data: newSeries }];
            }
      },


     


      generatePieChartOptions(labels, colors) {
          return {
            chart: {
              width: 320,
              height: 290, // Usar altura personalizada, se fornecida, caso contrário, use 280
              type: 'pie',
            },
            labels: labels,
            plotOptions: {
              pie: {
                size: 100,
                dataLabels: {
                  offset: -10,
                },
              },
            },
            colors: colors, // Defina as cores desejadas aqui
            dataLabels: {
              formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [val.toFixed(1) + '%'];
              },
            },
            legend: {
              position: 'bottom', // Mova a legenda para o rodapé
              offsetX: 0, // Ajuste o recuo da legenda para o rodapé
            },
            responsive: [
              {
                breakpoint: 100,
                options: {
                  
                  legend: {
                    position: 'bottom', // Mova a legenda para o rodapé na versão responsiva
                    offsetY: 10, // Ajuste o recuo da legenda para o rodapé na versão responsiva
                  },
                },
              },
            ],
          };
        },


  generateRadialChartOptions() {
  return {
    labels: ['Médias'],
    chartOptions: {
            chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '70%',
                }
              },
            },
            


          },
  }
},



      generateBarChartOptions(labels, colors, serie) {

        //const maxIndex = this.findIndexOfMaxValue(serie[0].data);
        //const modifiedColors = colors.map((color, index) => (index === maxIndex) ? '#42f5da' : color);

          return {

            chart: {
              type: "bar",
              height: 350,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                                distributed: true,
                                horizontal: true,
                                dataLabels: {
                                  position: 'bottom'
                                },
              },
            },
            dataLabels: {
              enabled: true,
              textAnchor: "start",
              style: {
                         colors: ['#000000'],
                                fontWeight: 'normal', // Defina a espessura da fonte como 'normal' para remover o negrito
                              },
                              formatter: function (val, opt) {
                                const percent = ((val / opt.w.globals.seriesTotals[opt.seriesIndex]) * 100).toFixed(2) + '%';
                                return val + " - (" + percent + ")";
                              },
            },
            colors: colors, // Defina as cores aqui
            xaxis: {
              categories: labels,
              trim: true,
              labels: {
                show: false,
              },
            },
          };
  

      },

     
      medias(){

        Contrato.media_falecimento(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany , this.selectedType, this.selectedOpcao, this.produto).then(response => {
          this.media_uso = "0";
                      if(response.status === 200){
                        const novoValor = response.data;
                        this.media_uso = novoValor;
                       
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  


           this.media_titular = "0";
           Contrato.media_titular(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany , this.selectedType, this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                        const novoValor = response.data;
                        this.media_titular = novoValor;
                       
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  
        
           this.media_dependente = "0";
           Contrato.media_dependente(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany , this.selectedType, this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                        const novoValor = response.data;
                        this.media_dependente = novoValor;
                       
                      } 


                    }).catch(e => {    
                    }).finally(() => {
                    })  


          this.media_agregado = "0";
           Contrato.media_agregado(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany , this.selectedType, this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                        const novoValor = response.data;
                        this.media_agregado = novoValor;
                       
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  




      },
      dashboard() {

          this.card1_loading = true,
          this.card2_loading = true,
          this.card3_loading = true,
          this.card4_loading = true,



                   Contrato.contrato_ativo( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                      
                         this.cards.contratos_ativos_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.contratos_ativos_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.contratos_ativos = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.card1_loading = false
                    })  

                    Contrato.total_vidas(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                         
                         this.cards.vidas_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.vidas_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.vidas = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.card2_loading = false
                    })  

                    Contrato.total_cancelado(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                         
                         this.cards.contratos_cancelados_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.contratos_cancelados_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.contratos_cancelados = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    }) 

                    Contrato.total_dependente(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                         
                         this.cards.dependentes_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.dependentes_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.dependentes = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.card3_loading = false

                    })  

                    Contrato.total_dependente_falecido(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                         
                         this.cards.dependentes_falecidos_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.dependentes_falecidos_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.dependentes_falecidos = total_a;
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  


                    Contrato.total_agregado(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                         this.cards.agregados_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.agregados_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.agregados = total_a;
                      
                      } ƒmedia
                    }).catch(e => {    
                    }).finally(() => {
                      this.card4_loading = false

                    })  

                    Contrato.total_agregado_falecido(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto).then(response => {
                      if(response.status === 200){
                         this.cards.agregados_falecidos_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.agregados_falecidos_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.agregados_falecidos = total_a;
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                   

                    Contrato.total_titular(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), "Todas", "Todos", this.selectedOpcao, this.produto ).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_titulares_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_titulares_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
                         this.cards.total_titulares = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  


                   


      }

    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  </style>
